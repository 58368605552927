<template>
    <smu-menu-dropdown v-if="languages" :items="languageKeys">
        <template #value>{{ locale.toUpperCase() }}</template>
        <template #item="{ item }">
            <div class="w-full p-3" @click="locale = item">
                {{ languages[item] }}
            </div>
        </template>
    </smu-menu-dropdown>
</template>

<script setup lang="ts">
const { locale } = storeToRefs(useLocalSettingsStore());

const { data } = storeToRefs(useUserStore());

const languages = computed(() =>
    data.value?.app.languages.reduce(
        (accumulator, language) => ({
            ...accumulator,
            [language.code]: language.name[language.code],
        }),
        {} as Record<string, string>,
    ),
);

const languageKeys = computed(() => {
    if (languages?.value) {
        return Object.keys(languages.value);
    }
});
</script>
