<template>
    <smu-main-menu>
        <smu-menu-section
            v-for="section in filteredSections"
            :key="section.name"
            :name="section.name"
            :has-active-links="sectionHasActiveLinks(section.links)"
        >
            <template v-for="item in section.links" :key="item.name">
                <smu-menu-section-entry
                    :is-beta="item.beta"
                    :is-dev="item.dev"
                    :is-active="isActive(item.url)"
                    class="w-full"
                >
                    <nuxt-link
                        v-if="linkIsAuthorized(item)"
                        :to="item.url"
                        class="active:text-primary flex h-full w-full"
                        active-class="text-primary"
                        @click="resetSearchStores()"
                    >
                        <span class="w-6">
                            <smu-icon v-bind="item.icon" />
                        </span>

                        {{ item.name }}
                    </nuxt-link>
                </smu-menu-section-entry>
            </template>
        </smu-menu-section>
    </smu-main-menu>

    <div v-if="isAuthorised({ permission: 'manage go-to-legacy-backend' })">
        <menu-redirect />
    </div>
</template>

<script setup lang="ts">
import type { SmuIconProps } from "@smakecloud/smake-ui";

type Link = {
    name: string;
    icon: SmuIconProps;
    url: string;
    dev?: boolean;
    beta?: boolean;
    permission?: string;
};

type Section = {
    name: string;
    links: Link[];
};

const { t } = useI18n();
const { isAuthorised } = useAuthorisationChecker();
const router = useRouter();
const route = useRoute();
const { slug } = useSlugStore();

function isActive(url: string) {
    const regex = new RegExp(url + ".*$");
    return regex.test(route.path);
}

function sectionHasActiveLinks(links: Link[]) {
    for (const link of links) {
        if (isActive(link.url)) {
            return true;
        }
    }

    return false;
}

const linkIsAuthorized = (link: Link) => {
    return isAuthorised(link);
};

const sections = computed((): Section[] => [
    {
        name: "",
        links: [
            {
                name: t("shop/backend.dashboard.menu.title"),
                icon: {
                    type: "light",
                    icon: "tachometer-alt",
                },
                url: `/${slug}/dashboard`,
            },
        ],
    },
    {
        name: t("shop/backend.sidebar.catalogs"),
        links: [
            {
                name: t("shop/backend.modifications.menu.title"),
                icon: {
                    type: "light",
                    icon: "hexagon-image",
                },
                url: `/${slug}/catalog/logos`,
                permission: "manage logo",
            },
            {
                name: t("eloquent.models.customer", 2),
                icon: {
                    type: "light",
                    icon: "users",
                },
                url: `/${slug}/customers`,
                permission: "manage customer",
            },
        ],
    },
]);

const filteredSections = computed(() =>
    sections.value.filter(
        (section) =>
            section.links.filter((link) => {
                const route = router.resolve(link.url);

                return isAuthorised(
                    route.matched.length === 0 ? link : route.meta,
                );
            }).length > 0,
    ),
);

const customerSearchStore = useCustomerSearchStore();
const logoSearchStore = useLogoSearchStore();
const { filter } = storeToRefs(logoSearchStore);
function resetSearchStores() {
    const router = useRouter();

    const unwatch = router.afterEach(() => {
        customerSearchStore.searchPageVisited = false;
        customerSearchStore.changeSearch();

        logoSearchStore.searchPageVisited = false;
        logoSearchStore.changeSearch();

        filter.value = {};
        unwatch();
    });
}
</script>
